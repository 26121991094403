
 .carousel-inner img {
     margin: auto;
 }

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid black;
}

.arrow.dsc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid black;
}
table thead tr th {
    cursor: pointer;
}

.for-page-button {

    margin-top:20px;
    margin-left:20px;
}

.search-box {

    width: 150px;
    margin: 10px;

}

.number-input {

    border: 1px solid #C3C0C0;
    width: 60px;

}

.grid-results {

    margin: 10px;
}

.white-font > a {

      color: white;
  }

 .white-font > a:hover {

     color: #d7dbe2;

 }

 .white-font-15 > a {

     color: white;
     font-size: 15px;
 }

 .white-font-15 > a:hover {

     color: #d7dbe2;
     font-size: 15px;

 }

 .margin-10 {

   margin: 10px;
 }

 .margin-20 {

   margin: 20px;
 }

 .margin-30 {

   margin: 30px;
 }

 .margin-top-20 {

   margin-top: 20px;

 }

 .margin-top-10 {

   margin-top: 10px;

 }

 .margin-bottom-10 {

   margin-top: 10px;

 }

 .form-border {

   width: 100%;
   min-width: 250px;
   color: #555;
   border: 1px solid #ccc;
   border-radius: 4px;
   padding: 15px;
   background-color: #f9fafc;

 }

 .min-width-200 {

   min-width: 200px;

 }

 .min-width-300 {

   min-width: 200px;

 }

 .guest-footer {
   background: #fff;
   padding: 15px;
   width: auto;
   color: #444;
   border-top: 1px solid #d2d6de;
   position: relative;
   clear:both;
   padding-top:20px;
   padding-bottom: 20px;

 }

 .body-fill{

   margin-top: 10px;
   margin-bottom: 50px;

 }

 .submit-email-button {

   margin-top: 20px;
   margin-left: 70px;

 }

 .reset-password-button{


   margin-top:  20px;
   margin-left:  95px;

 }
